import { Component, Input, OnInit } from '@angular/core';
import { FormNutrition } from "../../../models/nutrition";
import { NutritionService } from "../../../services/nutrition.service";
import { OsmConstant } from "../../../models/constant";
import { Router } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ServiceUtils } from 'src/app/services/utils.service';
import { FormBaseWithFieldCareNeeded } from 'src/app/Base/form-base-care-needed';

@Component({
  selector: 'app-form-nutrition',
  templateUrl: './form-nutrition.component.html',
  styleUrls: ['./form-nutrition.component.css']
})
export class FormNutritionComponent extends FormBaseWithFieldCareNeeded implements OnInit {
    
  constructor(private nutritionService: NutritionService, private route: Router,
    private service: ServiceUtils) {
    super(service);
  }

  @Input() formNutrition: FormNutrition = new FormNutrition();
  @Input() patientId: string;
  @Input() missionDataId: string;

  missionTypes = ['Program', 'Bootcamp', 'Weekly', 'Six Weeks'];

  disableCareNeededForEntity = ["Obturator"];


  ngOnInit() {
    this.initializeDisplayImage(this.formNutrition.photo ? this.imageSuffix + this.formNutrition.photo : null);
    this.loadCareNeeded();
  }

  onSave() {
    this.formNutrition.mission_datas_id = this.missionDataId;
    this.formNutrition.patients_id = this.patientId;
    this.nutritionService.saveNutrition(this.formNutrition).then((resp) => {
      this.toast.fire({
        icon: 'info',
        title: resp.message
      })
      setTimeout(() => {
        this.afterAction.emit(resp.data);
      }, 500);
    }).catch((error) => {
      if ((error.error) && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
        this.route.navigate(["/login"]);
      }
      else {
        this.toast.fire({
          icon: 'error',
          title: error.statusText ? error.statusText : error.error.message
        })
      }
    })
  }

  addPhoto(imageInBase64, imageIndex = 0) {
    this.formNutrition.photo = this.handleImage(imageInBase64)[imageIndex];
  }

  
  onDelete() {
    throw new Error("Method not implemented.");
  }

}
