import { Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GalleryService } from 'src/app/services/gallery.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
declare var $;

@Component({
  selector: 'app-detail-photos',
  templateUrl: './detail-photos.component.html',
  styleUrls: ['./detail-photos.component.css']
})
export class DetailPhotosComponent implements OnInit {

  constructor(private activeRoute: ActivatedRoute, private route: Router, private galleryService: GalleryService) { }

  displayImages = [];

  images = [];

  files = [];

  filesInput = [];

  patientId: string = null;

  activepage = 'search-patient';

  btnAddPhotoShow = true;

  ngOnInit() {
    this.activeRoute.params.subscribe(params => {
      this.patientId = params["id"];

      // get all galleries

      this.galleryService.getAllGalleryForPatient(this.patientId).then((resp) => {
        console.log("gallery", resp);
        let galleries = resp.data ? resp.data.all_photos : [];
        // this.images = galleries.map(item => {
        //   return item.path_name;
        // });
        this.images = galleries;
      }).catch((error) => {
        console.log(error);
      })
    });
  }


  goBackToDetailPage() {
    return this.route.navigate(['/patient/detail/', this.patientId])
  }


  handleImageFromComputer(files) {
    this.btnAddPhotoShow = false;
    this.files = files;
    for (var index = 0, len = files.length; index < len; ++index) {
      const reader = new FileReader();
      reader.readAsDataURL(files[index]);
      reader.onload = () => {
        this.displayImages.push(reader.result);
        $('#modalPreview').modal('show');
      };
    }
  }


  cancel() {
    this.displayImages = [];
    this.filesInput = [];
    $('#modalPreview').modal('hide');
  }

  clearImage() {
    this.displayImages = [];
    this.filesInput = [];
    this.btnAddPhotoShow = true;
  }


  uploadFiles() {
    this.galleryService.saveFilmCrew(this.patientId, this.files).then((resp) => {
      let galleries = resp.data ? resp.data.all_photos : [];
        this.images = galleries;
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      $('#modalPreview').modal('hide');
    });
  }
}
