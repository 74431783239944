import { Component, Input, OnDestroy, OnInit, OnChanges } from '@angular/core';
import { ServiceUtils } from "../../../services/utils.service";
import { Patient } from "../../../models/patient";
import { Select2OptionData } from "ng-select2";
import { SelectBasic } from "../../../models/select";
import { UserService } from "../../../services/user-service.service";
import { OsmConstant } from "../../../models/constant";
import { Router } from "@angular/router";
import { Districts } from "../../../models/districts";
import { DatepickerOptions } from "ng2-datepicker";
import { PatientService } from "../../../services/patient.service";


import Swal from 'sweetalert2';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { StateService } from "../../../services/state.service";
declare var $;

@Component({
  selector: 'app-form-patient',
  templateUrl: './form-patient.component.html',
  styleUrls: ['./form-patient.component.css']
})
export class FormPatientComponent implements OnInit, OnDestroy, OnChanges {

  activepage = 'form-patient';

  distrctsEntities;
  regionsEntities;
  pathologiesEntities;
  registrationChannelsEntities;

  districtsForRegion = [];

  districts: Array<Select2OptionData>;
  regions: Array<Select2OptionData>;
  pathologues: Array<Select2OptionData>;
  registrationChannels: Array<Select2OptionData>;
  options;

  displayedImage: any;

  action: string = "SAVE";

  toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 50000,
    timerProgressBar: false,
  });

  patientIdForAlreadyExist: string = "";

  @Input() patient: Patient = new Patient();

  constructor(private serviceUtils: ServiceUtils, private userService: UserService, private route: Router, private patientService: PatientService,
    private stateService: StateService) {
  }

  ngOnInit() {
    this.userService.checkUser();
    this.getPatientForUpdate();
    this.options = this.serviceUtils.getDatePickerOptions();
    this.serviceUtils.getFormPatientData().then((response) => {
      this.distrctsEntities = response.data ? response.data.districts : {};
      this.regionsEntities = response.data ? response.data.regions : {};
      this.pathologiesEntities = response.data ? response.data.pathologies : {};
      this.registrationChannelsEntities = response.data ? response.data.registration_chanel : {};
      this.districts = this.getSelect2OptionsData(this.distrctsEntities);
      this.regions = this.getSelect2OptionsData(this.regionsEntities);
      this.registrationChannels = this.getSelect2OptionsData(this.registrationChannelsEntities);
      this.pathologues = this.getSelect2OptionsData(this.pathologiesEntities);
    }).catch((error) => {
      if ((error.error) && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
        this.route.navigate(["/login"]);
      }
      else {
        console.log(error);
      }
    });
  }

  ngOnChanges(changes: any) {
    console.log("changes detected")
    if (changes.patient && changes.patient.date_of_birth && changes.patient.date_of_birth.currentValue) {
      console.log(changes.patient.date_of_birth.currentValue)
    }
  }


  forceInsert(){
    this.patient.force = 1;
    this.savePatient();
  }


  savePatient() {
    if (this.action === 'SAVE') {
      this.patientService.savePatient(this.patient).then((resp) => {
        if (resp.status == 'failed' && resp.message.includes(OsmConstant.alreadyExistsKeyWord)) {
          this.patientIdForAlreadyExist = resp.data ? resp.data.patient ? resp.data.patient.id : null : null;
          $('#modal_confirm_exist').modal('show');
        } else {
          this.toast.fire({
            icon: 'success',
            title: resp.message
          })
          let patientId = (resp.data && resp.data.patient) ?
            this.route.navigate(['/patient/detail/', resp.data.patient.id]) : {};
        }

      }).catch((error) => {
        if ((error.error) && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
          this.route.navigate(["/login"]);
        }
        else {
          this.toast.fire({
            icon: 'error',
            title: error.statusText ? error.statusText : error.error.message
          })
        }
      })
    } else {
      this.patientService.editPatient(this.patient).then((resp) => {
        this.toast.fire({
          icon: 'success',
          title: resp.message
        })
        let patientId = (resp.data && resp.data.patient) ?
          this.route.navigate(['/patient/detail/', resp.data.patient.id]) : {};
      }).catch((error) => {
        if ((error.error) && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
          this.route.navigate(["/login"]);
        }
        else {
          this.toast.fire({
            icon: 'error',
            title: error.statusText ? error.statusText : error.error.message
          })
        }
      })
    }
  }

  handleImage(event) {
    this.displayedImage = event;
    this.setPhoto(event);
  }

  getSelect2OptionsData(dataObject) {
    let values = [];
    dataObject.forEach(element => {
      const registration = <Select2OptionData>{ "id": element.id, "text": element.name };
      values.push(registration);
    })
    return values;
  }


  choseDistrict(e) {
    console.log('change district', e)
    this.getDistrictForRegion(e);
  }


  getPatientForUpdate() {
    this.stateService.data.subscribe(data => {
      if (data) {
        this.patient = new Patient(
          data.photo_url,
          data.name,
          data.firstname,
          data.age,
          data.address,
          data.contact1,
          data.pathologies_id,
          this.convert(data.date_of_birth),
          data.patient_advocate,
          data.contact2,
          data.reference1,
          data.reference2,
          data.districts_id,
          data.regions_id,
          data.registration_chanels_id,
          data.patient_story,
          data.sex,
          null,
          data.id
        );
        this.displayedImage = OsmConstant.BASE_URL_SANS_VERSION + data.photo_url;
        this.action = "UPDATE";
      }
    })
  }


  changeDate() {
    console.log(this.patient.date_of_birth);
  }

  ngOnDestroy() {
    this.stateService.removeState();
  }


  convert(str) {
    if (str) {
      let date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    }
    return str;
  }

  getDistrictForRegion(regionId: string) {
    if (regionId) {
      this.districtsForRegion = [];
      this.distrctsEntities.forEach(element => {
        if (element.regions_id == regionId) {
          this.districtsForRegion.push(element);
        }
      });
      this.districts = this.getSelect2OptionsData(this.districtsForRegion);
    }
  }


  setPhoto(e) {
    const imageName = 'name.png';
    const imageBlob = this.dataURItoBlob(e);
    const imageFile = new File([imageBlob], imageName, { type: 'image/png' });
    this.patient.photo = imageFile;
  }


  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }

}
