import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Patient, Pagination } from "../../../models/patient";
import { Router } from "@angular/router";
import { StateService } from "../../../services/state.service";
import { OsmConstant } from "../../../models/constant";
import { PatientService } from 'src/app/services/patient.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UserService } from 'src/app/services/user-service.service';

@Component({
  selector: 'table-patient',
  templateUrl: './table-patient.component.html',
  styleUrls: ['./table-patient.component.css']
})
export class TablePatientComponent implements OnInit, OnChanges {
  @Input() patients: Patient[] = [];
  @Input() paginate!: Pagination;
  @Input() withOptions = false;
  @Input() optionForSurgery = false;
  @Input() optionForNutririon = false;
  @Input() optionForFC = false;
  @Input() schedulePriority?: number;
  @Input() url = '';
  @Input() category = false;

  imageSuffix = OsmConstant.BASE_URL_SANS_VERSION;
  borderColorClasses: string[] = [];
  userAdmin = true;

  tableHeaders = [
    { label: 'Name', width: '15%' },
    { label: 'First name', width: '15%' },
    { label: 'Age', width: '11%' },
    { label: 'Address', width: '15%' },
    { label: 'Contact', width: '10%' },
    { label: 'Pathology', width: '9%' },
    { label: 'Registration date', width: '12%' },
  ];

  patientFields = [
    { value: 'name', width: '15%' },
    { value: 'firstname', width: '15%' },
    { value: 'age_string', width: '11%' },
    { value: 'address', width: '15%' },
    { value: 'contact1', width: '10%' },
    { value: 'pathology?.name', width: '9%' },
    { value: 'created | date : "dd/MM/yyyy"', width: '12%' },
  ];

  constructor(
    private route: Router,
    private userService: UserService,
    private stateService: StateService,
    private patientService: PatientService
  ) {}

  ngOnInit(): void {
    this.userAdmin = this.userService.isUserAdmin(this.userService.getUserInfo());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.patients && changes.patients.currentValue) {
      this.initializeBorderColorClasses();
    }
  }

  initializeBorderColorClasses(): void {
    const borderClasses = ['table_of_patient', 'table_of_patient2', 'table_of_patient3', 'table_of_patient4', 'table_of_patient5'];
    this.borderColorClasses = this.patients.map(
      () => borderClasses[Math.floor(Math.random() * borderClasses.length)]
    );
  }

  getBorderColorForTable(index: number): string {
    return this.borderColorClasses[index];
  }

  update(index: number): void {
    this.stateService.addState(this.patients[index]);
    this.route.navigate(['patient/form']);
  }

  resolveFieldValue(patient: Patient, field: string): string {
    try {
      return field.split('.').reduce((value: any, key) => value[key], patient) || '';
    } catch {
      return '';
    }
  }
}
