import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { OsmConstant } from 'src/app/models/constant';
import { Pagination, Patient } from 'src/app/models/patient';
import { SurgeryService } from 'src/app/services/surgery.service';
import { UserService } from 'src/app/services/user-service.service';

@Component({
  selector: 'app-surgery-global-list',
  templateUrl: './surgery-global-list.component.html',
  styleUrls: ['./surgery-global-list.component.css']
})
export class SurgeryGlobalListComponent implements OnInit {

  constructor(private surgeryService: SurgeryService, private route: Router, private userService: UserService) { }

  activepage = 'surgery';
  patients: Array<Patient>;
  pagination: Pagination;

  sourceForSearchBar = 'SurgeriesNeeded';
  urlForSearch: string;

  ngOnInit() {
    this.userService.checkUser();
    this.surgeryService.getAllPatientSurgeryGlobal().then((resp) => {
      this.patients = resp.data ? resp.data.patients : {};
      this.pagination = resp.data ? resp.data.paginate ? resp.data.paginate.Patients : null : null;
    }).catch((error) => {
      if ((error.error) && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
        this.route.navigate(["/login"]);
      }
    })
  }

  handleResult(data) {
    this.patients = data ? data.patients : [];
    this.pagination = data ? data.paginate ? data.paginate.Patients : null : null;
  }

  handleUrl(url) {
    this.urlForSearch = url;
  }

}
