import {Component, Input, OnInit} from '@angular/core';
import {Patient} from "../../../models/patient";
import {PatientService} from "../../../services/patient.service";
import {OsmConstant} from "../../../models/constant";
import {Router} from "@angular/router";
import {NgxUiLoaderService} from "ngx-ui-loader";

@Component({
  selector: 'detail-patient-utils',
  templateUrl: './detail-patient-utils.component.html',
  styleUrls: ['./detail-patient-utils.component.css']
})
export class DetailPatientUtilsComponent implements OnInit {

  constructor(private patientService: PatientService, private route: Router) { }

  patient: Patient;

  imageSuffix = OsmConstant.BASE_URL_SANS_VERSION;

  @Input() patientId: string;

  ngOnInit() {
    this.patientService.viewPatientDetails(this.patientId).then((resp) => {
      this.patient = resp.data? resp.data.patient : {};
    }).catch((error) => {
      if ((error.error) && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
        this.route.navigate(["/login"]);
      }
    })
  }

}
