import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { SplashComponent } from './components/splash/splash.component';
import { AppRoutingModule } from "./app-routing-module";
import { LoginComponent } from './components/login/login.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HomeComponent } from './components/home/home.component';
import { MenuComponent } from './components/menu/menu.component';
import { TablePatientComponent } from './components/Patient/table-patient/table-patient.component';
import { FormPatientComponent } from './components/Patient/form-patient/form-patient.component';
import { NgSelect2Module } from "ng-select2";
import { SearchPatientComponent } from './components/Patient/search-patient/search-patient.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { DetailPatientComponent } from './components/Patient/detail-patient/detail-patient.component';
import { DetailPatientUtilsComponent } from './components/utils/detail-patient-utils/detail-patient-utils.component';
import { FormMissionComponent } from './components/utils/form-mission/form-mission.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgDatepickerModule } from "ng2-datepicker";
import { NotifierModule } from "angular-notifier";
import { UsersComponent } from './components/users/users.component';
import { TableUsersComponent } from './components/utils/table-users/table-users.component';
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { FormSurgeryComponent } from './components/utils/form-surgery/form-surgery.component';
import { SurgeryListComponent } from './components/Surgery/surgery-list/surgery-list.component';
import { SurgeryDetailComponent } from './components/Surgery/surgery-detail/surgery-detail.component';
import { SurgeryTabsComponent } from './components/utils/surgery-tabs/surgery-tabs.component';
import { NutritionListComponent } from './components/Nutrition/nutrition-list/nutrition-list.component';
import { NutritionDetailComponent } from './components/Nutrition/nutrition-detail/nutrition-detail.component';
import { ScheduleComponent } from './components/schedule/schedule.component';
import { CloseFolderComponent } from './components/utils/close-folder/close-folder.component';
import { GalleryComponent } from './components/utils/gallery/gallery.component';
import { FilmcrewDetailComponent } from './components/FilmCrew/filmcrew-detail/filmcrew-detail.component';
import { FilmcrewFormComponent } from './components/utils/filmcrew-form/filmcrew-form.component';
import { FilmcrewListComponent } from './components/FilmCrew/filmcrew-list/filmcrew-list.component';
import { HeaderComponent } from './components/header/header.component';
import { SettingsComponent } from './components/settings/settings.component';
import { CameraComponent } from './components/utils/camera/camera.component';
import { WebcamModule } from 'ngx-webcam';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { SafeHtmlPipe } from './components/utils/safeHtml';
import { DateFormaterPipe } from './components/utils/date-formatter';
import { SettingsTableComponent } from './components/utils/settings-table/settings-table.component';
import { CategoryComponent } from './components/category/category.component';
import { FormNutritionComponent } from './components/utils/form-nutrition/form-nutrition.component';
import { NotificationDetailsComponent } from './components/notification-details/notification-details.component';
import { LightboxModule } from 'ngx-lightbox';
import { DetailPhotosComponent } from './components/utils/detail-photos/detail-photos.component';
import { CameraMultipleComponent } from './components/utils/camera-multiple/camera-multiple.component';
import { SurgeryGlobalListComponent } from './components/Surgery/surgery-global-list/surgery-global-list.component';
import { DeletePatientComponent } from './components/utils/delete-patient/delete-patient.component';
import { LoadingInterceptor } from './interceptor/loading.interceptor';
import { LoaderComponent } from './components/loader/loader.component';


@NgModule({
  declarations: [
    AppComponent,
    SplashComponent,
    LoginComponent,
    HomeComponent,
    MenuComponent,
    TablePatientComponent,
    FormPatientComponent,
    SearchPatientComponent,
    SearchBarComponent,
    DetailPatientComponent,
    DetailPatientUtilsComponent,
    FormMissionComponent,
    UsersComponent,
    TableUsersComponent,
    FormSurgeryComponent,
    SurgeryListComponent,
    SurgeryDetailComponent,
    SurgeryTabsComponent,
    NutritionListComponent,
    NutritionDetailComponent,
    ScheduleComponent,
    CloseFolderComponent,
    GalleryComponent,
    FilmcrewDetailComponent,
    FilmcrewFormComponent,
    FilmcrewListComponent,
    HeaderComponent,
    SettingsComponent,
    CameraComponent,
    SafeHtmlPipe,
    DateFormaterPipe,
    SettingsTableComponent,
    CategoryComponent,
    FormNutritionComponent,
    NotificationDetailsComponent,
    DetailPhotosComponent,
    CameraMultipleComponent,
    SurgeryGlobalListComponent,
    DeletePatientComponent,
    LoaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelect2Module,
    HttpClientModule,
    NgDatepickerModule,
    NotifierModule,
    NgxUiLoaderModule,
    WebcamModule,
    EditorModule,
    LightboxModule

  ],
  providers: [
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    FormMissionComponent,
    FormSurgeryComponent,
    FormNutritionComponent,
    TablePatientComponent,
    FilmcrewFormComponent
  ]
})
export class AppModule { }
