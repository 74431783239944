import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services/loader-service.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
  isLoading: boolean = true;

  constructor(private loaderService: LoaderService) {
    this.loaderService.isLoading.subscribe((value) => {
        this.isLoading = value;
    });
  }

  ngOnInit() {}
}
