import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Select2OptionData } from "ng-select2";
import { ServiceUtils } from "../../services/utils.service";
import { OsmConstant } from "../../models/constant";
import { FormSearchPatient, Patient } from "../../models/patient";
import { PatientService } from "../../services/patient.service";
import { Router } from "@angular/router";
import { FilmCrewService } from 'src/app/services/film-crew.service';
import { NutritionService } from 'src/app/services/nutrition.service';
import { SurgeryService } from 'src/app/services/surgery.service';
import Swal from 'sweetalert2';
declare var $;

@Component({
  selector: 'search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css']
})
export class SearchBarComponent implements OnInit {
  districts: Array<Select2OptionData> = [];
  regions: Array<Select2OptionData> = [];
  pathologues: Array<Select2OptionData> = [];
  processingConditions: Array<Select2OptionData> = [];
  careNeeded: Array<Select2OptionData> = [];
  surgeryNeeded: Array<Select2OptionData> = [];
  allLocations: Array<any> = [];

  formSearch: FormSearchPatient = new FormSearchPatient();
  simpleSearchKey: string = '';
  emailPrint: string = '';
  patients: Array<Patient> = [];
  displayResultNumber: boolean = false;
  resultNumber: number = 0;
  labelAndValue: Array<LabelAndValue> = [];
  shouldEnable: boolean = true;

  @Output() simpleSearchPatient = new EventEmitter<any>();
  @Output() filterSearchPatient = new EventEmitter<any>();
  @Output() url = new EventEmitter<any>();
  @Input() sources: string = '';
  @Input() priority: number = 0;
  @Input() comment: string = '';

  distrctsEntities: any; 
  regionsEntities: any; 
  pathologiesEntities: any; 
  processingConditionsEntities: any; 
  registrationChannels: any; 
  patientLodgins: any; 
  careNeededEntities: any; 
  surgeryNeededEntities: any; 
  dataCollectedDurings: any = [];

  show: boolean = true;

  patientPropertySearch = [
    { id: "new", text: "Newly registered" },
    { id: "already_exist", text: "Already came to a Program" },
    { id: "closed_mission_data", text: "Comprehensive care provided" }
  ];

  dataCollectedDuringForNutrition = [
    { id: "Program", text: "Program" },
    { id: "Bootcamp", text: "Bootcamp" },
    { id: "Weekly", text: "Weekly" },
    { id: "Six Weeks", text: "Six Weeks" }
  ];

  dataCollectedDuringForSurgery = [
    { id: "1", text: "One week" },
    { id: "6", text: "Six months" },
    { id: "12", text: "One year" }
  ];

  followUpNutrition = [
    { id: "nutrition", text: "Nutrition" }
  ];

  followUpSurgery = [
    { id: "surgery", text: "Surgery" }
  ];

  followUp = [
    { id: "surgery", text: "Surgery" },
    { id: "nutrition", text: "Nutrition" }
  ];

  drowpdownOptionsForMultiple = { multiple: true };

  statusDropDownData = [
    { id: "closed", text: "CLOSED" },
    { id: "open", text: "OPEN" }
  ];

  toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
  });

  constructor(
    private serviceUtils: ServiceUtils,
    private patientService: PatientService,
    private route: Router,
    private filmCrewService: FilmCrewService,
    private nutritionService: NutritionService,
    private surgeryService: SurgeryService
  ) { }

  ngOnInit(): void {
    this.show = ['patients', 'Schedule'].includes(this.sources);

    this.fetchDistricts();
    this.fetchRegions();
    this.fetchPathologies();
    this.fetchProcessingConditions();
    this.fetchRegistrationChannels();
    this.fetchPatientLodgings();
    this.fetchCareNeeded();
    this.fetchSurgeryNeeded();
    this.fetchLocations();

    this.followUp = this.sources === 'Nutritions' ? this.followUpNutrition : this.sources === 'Surgeries' ? this.followUpSurgery : this.followUp;
  }

  fetchDistricts(): void {
    this.serviceUtils.getAlldistrict()
      .then(response => {
        this.distrctsEntities = response.data ? response.data.districts : [];
        this.districts = this.getSelect2OptionsData(this.distrctsEntities);
      })
      .catch(this.handleFetchError);
  }

  fetchRegions(): void {
    this.serviceUtils.getAllRegion()
      .then(response => {
        this.regionsEntities = response.data ? response.data.regions : [];
        this.regions = this.getSelect2OptionsData(this.regionsEntities);
      })
      .catch(this.handleFetchError);
  }

  fetchPathologies(): void {
    this.serviceUtils.getAllPathologies()
      .then(response => {
        this.pathologiesEntities = response.data ? response.data.pathologies : [];
        this.pathologues = this.getSelect2OptionsData(this.pathologiesEntities);
      })
      .catch(this.handleFetchError);
  }

  fetchProcessingConditions(): void {
    this.serviceUtils.getAllProcessingConditions()
      .then(response => {
        this.processingConditionsEntities = response.data ? response.data.type_mission_datas : [];
        this.processingConditions = this.getSelect2OptionsData(this.processingConditionsEntities);
      })
      .catch(this.handleFetchError);
  }

  fetchRegistrationChannels(): void {
    this.serviceUtils.getAllRegistrationChannels()
      .then(response => {
        this.registrationChannels = response.data ? response.data.registration_chanel : [];
      })
      .catch(this.handleFetchError);
  }

  fetchPatientLodgings(): void {
    this.serviceUtils.getAllPatientLodgin()
      .then(response => {
        this.patientLodgins = response.data ? response.data.patient_lodgin : [];
      })
      .catch(this.handleFetchError);
  }

  fetchCareNeeded(): void {
    this.serviceUtils.getAllCareNeeded()
      .then(response => {
        this.careNeededEntities = response.data ? response.data.care_neededs : [];
        this.careNeeded = this.getSelect2OptionsData(this.careNeededEntities);
      })
      .catch(this.handleFetchError);
  }

  fetchSurgeryNeeded(): void {
    this.serviceUtils.getAllSurgeryNeeded()
      .then(response => {
        this.surgeryNeededEntities = response.data ? response.data.surgery_neededs : [];
        this.surgeryNeeded = this.getSelect2OptionsData(this.surgeryNeededEntities);
      })
      .catch(this.handleFetchError);
  }

  fetchLocations(): void {
    this.serviceUtils.getAllLocations()
      .then(response => {
        this.allLocations = response.data ? response.data.locations : [];
      })
      .catch(console.log);
  }

  private handleFetchError = (error: any): void => {
    if (error && error.error && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
      this.route.navigate(["/login"]);
    } else {
      console.log(error);
    }
  };

  private getSelect2OptionsData(dataObject): Array<Select2OptionData> {
    return dataObject.map(element => ({ id: element.id, text: element.name }));
  }

  onSearch(): void {
    const url = this.getUrlForSearchSimple();
    this.formSearch = new FormSearchPatient();
    this.patientService.searchSimple(url)
      .then(resp => {
        this.patients = resp.data ? resp.data.patients : [];
        this.simpleSearchPatient.emit(resp.data);
        this.url.emit(url);
        this.displayResultNumber = true;
        this.resultNumber = resp.data && resp.data.paginate && resp.data.paginate.Patients ? resp.data.paginate.Patients.count : 0;
      })
      .catch(error => {
        if (error && error.error && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
          this.route.navigate(["/login"]);
        }
      });
  }

  private getUrlForSearchSimple(): string {
    switch (this.sources) {
      case 'patients':
        return this.patientService.getSearchPatientSimpleUrl(this.simpleSearchKey);
      case 'FilmeCrews':
        return this.filmCrewService.getSearchUrlForPatientFilmCrew(this.simpleSearchKey);
      case 'Nutritions':
        return this.nutritionService.getSearchUrlForPatientNutritions(this.simpleSearchKey);
      case 'Surgeries':
        return this.surgeryService.getUrlForSearch(this.simpleSearchKey);
      case 'SurgeriesNeeded':
        return this.surgeryService.getUrlSearchForSurgeryNeeded(this.simpleSearchKey);
      case 'Schedule':
        return this.patientService.getSearchUrlForSchedule(this.simpleSearchKey, this.priority);
      case 'Category':
        return this.patientService.getSearchUrlForCategory(this.simpleSearchKey, this.priority);
      default:
        return '';
    }
  }

  doSearch(): void {
    const url = this.getUrlForSearchAdvanced();
    this.patientService.searchAdvanced(url)
      .then(resp => {
        this.patients = resp.data      ? resp.data.patients : [];
        this.filterSearchPatient.emit(resp.data);
        this.url.emit(url);

        this.displayResultNumber = true;
        this.resultNumber = resp.data && resp.data.paginate && resp.data.paginate.Patients ? resp.data.paginate.Patients.count : 0;
        this.getLabelAndValue();
        $('.hide-modal').modal('hide');
      })
      .catch(error => {
        if (error && error.error && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
          this.route.navigate(["/login"]);
        }
        $('.hide-modal').modal('hide');
      });
  }

  getLabelAndValue(): void {
    this.labelAndValue = [];
    const fields = [
      'name', 'firstname', 'address', 'mission_date', 'chart_number', 'pathologies_id', 'districts_id',
      'regions_id', 'type_mission_datas_id', 'patient_advocate', 'registration_chanels_id', 'patient_lodgin',
      'start_mission_date', 'end_mission_date', 'bootcamp_date', 'age1', 'month1', 'age2', 'month2',
      'patient_property', 'contact', 'care_needed_id', 'surgery_needed_id', 'start_created', 'end_created',
      'follow_up_patient', 'complication', 'locations_id', 'follow_up', 'data_collected_during', 'data_collected_start',
      'data_collected_end', 'sex', 'status'
    ];
    fields.forEach(field => {
      const value = this.formSearch[field];
      if (value) {
        const label = this.formSearch.getLabel(field);
        const textValue = Array.isArray(value) ? this.getTextValueFromList(this[`${field}Entities`], value) : value;
        this.labelAndValue.push(new LabelAndValue(label, textValue));
      }
    });
  }

  print(): void {
    const url = this.getUrlForPrint(this.emailPrint);
    const isMethodPost = this.sources === 'Schedule';
    this.patientService.getPDFUrlForListPatient(url, isMethodPost)
      .then(resp => {
        $('#modal-print').modal('hide');
        this.toast.fire({
          icon: 'success',
          title: resp.data[0]
        });
      })
      .catch(error => {
        if (error && error.error && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
          this.route.navigate(["/login"]);
        }
      });
  }

  private getUrlForPrint(emailPrint: string): string {
    let url = '';
    const comment = this.getCommentForSource();
    if (this.simpleSearchKey != null) {
      url = this.getSimpleSearchUrlForPrint(emailPrint, comment);
    } else if (this.formSearch) {
      url = this.getAdvancedSearchUrlForPrint(emailPrint, comment);
    }
    return url;
  }

  private getCommentForSource(): string {
    switch (this.sources) {
      case 'Nutritions': return 'list of patients in nutritional follow-up';
      case 'Surgeries': return 'list of patients in surgical follow-up';
      case 'SurgeriesNeeded': return 'list of patients in surgical follow-up';
      case 'FilmeCrews': return 'list of patients in the film crew follow-up';
      default: return '';
    }
  }

  private getSimpleSearchUrlForPrint(emailPrint: string, comment: string): string {
    switch (this.sources) {
      case 'patients':
        return `${OsmConstant.BASE_URL}patients/index/pdf.json?email=${emailPrint}&key=${this.simpleSearchKey}`;
      case 'Nutritions':
        return this.nutritionService.getSearchUrlForPatientNutritionsForPrint(this.simpleSearchKey, emailPrint, comment);
      case 'Surgeries':
        return this.surgeryService.getUrlForSearchForPrint(this.simpleSearchKey, emailPrint, comment);
      case 'SurgeriesNeeded':
        return this.surgeryService.getUrlPrintForSurgeryNeeded(this.simpleSearchKey, emailPrint, comment);
      case 'FilmeCrews':
        return this.filmCrewService.getSearchUrlForPatientFilmCrewForPrint(this.simpleSearchKey, emailPrint, comment);
      case 'Schedule':
        return this.patientService.getSearchUrlForScheduleForPrint(this.simpleSearchKey, emailPrint, this.priority, this.comment);
      case 'Category':
        return this.patientService.getSearchUrlForCategoryForPrint(this.simpleSearchKey, emailPrint, this.priority, this.comment);
      default:
        return '';
    }
  }

  private getAdvancedSearchUrlForPrint(emailPrint: string, comment: string): string {
    const searchParams = Object.assign({}, this.formSearch, { email: emailPrint });
    switch (this.sources) {
      case 'patients':
        return `${OsmConstant.BASE_URL}patients/index/pdf.json?${this.patientService.getSearchPatientFilterUrl(searchParams, true)}`;
      case 'Nutritions':
        return this.nutritionService.getAdvancedSearchUrlForPrint(searchParams, comment);
      case 'Surgeries':
        return this.surgeryService.getAdvancedSearchUrlForPrint(searchParams, comment);
      case 'SurgeriesNeeded':
        return this.surgeryService.getAdvancedSearchUrlForPrintSurgeryNeeded(searchParams, comment);
      case 'FilmeCrews':
        return this.filmCrewService.getAdvancedSearchUrlForPrint(searchParams, comment);
      case 'Schedule':
        return this.patientService.getAdvancedSearchUrlForScheduleForPrint(searchParams, this.priority, this.comment);
      case 'Category':
        return this.patientService.getAdvancedSearchUrlForCategoryForPrint(searchParams, this.priority, this.comment);
      default:
        return '';
    }
  }

  private getTextValueFromList(list, ids): string {
    return Array.isArray(list)
      ? list.filter(item => ids.includes(item.id)).map(item => item.name).join(', ')
      : '';
  }

  findTextFromId(id: any, list: Array<any>): string {
    const found = list.find(item => item.id === id);
    return found ? (found.text || found.name) : '';
  }

  getUrlForSearchAdvanced(): string {
    switch (this.sources) {
      case 'Schedule':
        return this.patientService.getAdvancedSearchUrlForSchedule(this.formSearch, this.priority);
      case 'Category':
        return this.patientService.getAdvancedSearchUrlForCategory(this.formSearch, this.priority);
      case 'Nutritions':
        return this.patientService.getAdvancedSearchUrlForNutrition(this.formSearch);
      case 'Surgeries':
        return this.patientService.getAdvancedSearchUrlForSurgery(this.formSearch);
      case 'SurgeriesNeeded':
        return this.patientService.getAdvancedSearchUrlForSurgeryNeeded(this.formSearch);
      default:
        return this.patientService.getSearchPatientFilterUrl(this.formSearch);
    }
  }

  choseType(type: string): void {
    this.dataCollectedDurings = type === 'nutrition'
      ? this.dataCollectedDuringForNutrition
      : type === 'surgery'
        ? this.dataCollectedDuringForSurgery
        : [];
  }

  reset(): void {
    this.formSearch = new FormSearchPatient();
    this.simpleSearchKey = null;
  }

  changeGridEnable(): void {
    this.reset();
    this.shouldEnable = !this.shouldEnable;
  }
}

export class LabelAndValue {
  constructor(public label: string, public value: string) { }
}
