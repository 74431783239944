import { Component, OnInit } from '@angular/core';
import { Patient, Pagination } from "../../../models/patient";
import { SurgeryService } from "../../../services/surgery.service";
import { OsmConstant } from "../../../models/constant";
import { Router } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { UserService } from "../../../services/user-service.service";

@Component({
  selector: 'app-surgery-list',
  templateUrl: './surgery-list.component.html',
  styleUrls: ['./surgery-list.component.css']
})
export class SurgeryListComponent implements OnInit {

  constructor(private surgeryService: SurgeryService, private route: Router, private userService: UserService) { }

  activepage = 'surgery';
  patients: Array<Patient>;
  pagination: Pagination;

  sourceForSearchBar = 'Surgeries';
  urlForSearch: string;

  ngOnInit() {
    this.userService.checkUser();
    this.surgeryService.getAllPatientSurgery().then((resp) => {
      this.patients = resp.data ? resp.data.patients : {};
      this.pagination = resp.data ? resp.data.paginate ? resp.data.paginate.Patients : null : null;
    }).catch((error) => {
      if ((error.error) && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
        this.route.navigate(["/login"]);
      }
    })
  }

  handleResult(data) {
    this.patients = data ? data.patients : [];
    this.pagination = data ? data.paginate ? data.paginate.Patients : null : null;
  }

  handleUrl(url) {
    this.urlForSearch = url;
  }



}
