import { Component, OnInit } from '@angular/core';
import { PatientService } from "../../services/patient.service";
import { UserService } from "../../services/user-service.service";
import { OsmConstant } from "../../models/constant";
import { Router } from "@angular/router";
import { NgxUiLoaderModule, NgxUiLoaderService } from "ngx-ui-loader";
import { ServiceUtils } from 'src/app/services/utils.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  patients;
  activepage = "home";
  user: any;

  data: HomeData;

  constructor(private patientService: PatientService, private userService: UserService, private route: Router, private utilService: ServiceUtils) {
  }

  ngOnInit() {
    this.userService.checkUser();
    this.patientService.getAllPatient().then((resp) => {
      this.patients = resp.data.patients;
    }).catch((error) => {
      if ((error.error) && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
        this.route.navigate(["/login"]);
      }
      else {
        console.log(error);
      }
    })

    this.utilService.getDataForHomePage().then((response) => {
      this.data = response ? response.data ? response.data.data : null : null;
    }).catch((error) => {
      if ((error.error) && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
        this.route.navigate(["/login"]);
      }
      else {
        console.log(error);
      }
    })

    this.user = this.userService.getUserInfo();
  }

}
export interface HomeData {
  all: number;
  news: number;
  nutritions: number;
  surgeries: number;
}
