import { Component, Input, OnInit } from '@angular/core';
import { MissionDataService } from "../../../services/mission-data.service";
import { OsmConstant } from "../../../models/constant";
import { Router } from "@angular/router";
import { ServiceUtils } from "../../../services/utils.service";
import { FormMissionData } from "../../../models/mission-data";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { FormBase } from 'src/app/Base/form-base';

@Component({
  selector: 'app-form-mission',
  templateUrl: './form-mission.component.html',
  styleUrls: ['./form-mission.component.css']
})
export class FormMissionComponent extends FormBase implements OnInit {

  types: any;
  locations: any;
  patientLodgins: any;

  typeFiltered = [];

  constructor(private missionDataService: MissionDataService, private route: Router, private serviceUtils: ServiceUtils) {
    super();
  }

  @Input() patientId: string;
  @Input() formData: FormMissionData;

  ngOnInit() {
    console.log("isclose", this.isClose)
    this.missionDataService.getFormData(this.patientId).then((resp) => {
      this.types = resp.data ? resp.data.types : {};
      this.locations = resp.data ? resp.data.locations : {};
      this.patientLodgins = resp.data ? resp.data.patient_lodgin : {};
      this.initializeDisplayImage(this.formData.photo ? this.imageSuffix + this.formData.photo : null);
      this.filterType();
    }).catch((error) => {
      if ((error.error) && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
        this.route.navigate(["/login"]);
      }
      else {
        console.log(error);
      }
    })
  }


  addPhoto(imageInBase64: any, imageIndex = 0) {
    this.formData.photo = this.handleImage(imageInBase64)[imageIndex];
  }

  onSave() {
    this.formData.patients_id = this.patientId;

    this.missionDataService.saveMissionData(this.formData).then((resp) => {

      this.toast.fire({
        icon: 'info',
        title: resp.message
      })
      setTimeout(() => {
        this.afterAction.emit(resp.data);
      }, 500);
    }).catch((error) => {
      if ((error.error) && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
        this.route.navigate(["/login"]);
      }
      else {
        this.toast.fire({
          icon: 'error',
          title: error.statusText ? error.statusText : error.error.message
        })
      }
    })
  }


  filterType() {
    if (Array.isArray(this.types)) {
      this.types.forEach(element => {
        let enabled = element ? element.enabled : false;
        if (enabled) {
          this.typeFiltered.push(element);
        }
      });
    }
  }

  getValueForId(id: string, entity: string) {
    let list = [];
    switch (entity) {
      case 'processing_condition': { list = this.types }; break;
      case 'location': list = this.locations; break;
      case 'patientLodgins': list = this.patientLodgins; break;
    }
    if (Array.isArray(list)) {
      let value = "";
      list.forEach(element => {
        if (element.id == id) {
          value = element.name;
        }
      });
      return value;
    }
  }


  onDelete() {
    if (this.formData.id) {
      this.missionDataService.deleteFormMission(this.formData.id).then((resp) => {

        this.toast.fire({
          icon: 'info',
          title: resp.message
        })
        setTimeout(() => {
          console.log(resp);
          this.afterAction.emit(resp.data);
        }, 500);
      }).catch((error) => {
        if ((error.error) && error.error.code === 500 && error.error.message === OsmConstant.EXPIRED_TOKEN) {
          this.route.navigate(["/login"]);
        }
        else {
          this.toast.fire({
            icon: 'error',
            title: error.statusText ? error.statusText : error.error.message
          })
        }
      })
    }
  }
}
