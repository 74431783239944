import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { User } from "../../../models/user";
import { UserService } from 'src/app/services/user-service.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'table-users',
  templateUrl: './table-users.component.html',
  styleUrls: ['./table-users.component.css']
})
export class TableUsersComponent implements OnInit {

  constructor(private userService: UserService) { }

  @Input() users: Array<User>;

  @Output() afterDelete = new EventEmitter<any>();

  user: any;
  

  ngOnInit() {
    this.user = this.userService.getUserInfo();
  }

  deleteUser(userId: string) {
    if (confirm("Delete this user ?")) {
      this.userService.deleteUser(userId).then((data) => {
        this.afterDelete.emit(true);
      });
    }

  }

}
