import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { OsmConstant } from 'src/app/models/constant';
import { User } from 'src/app/models/user';
import { PatientService } from 'src/app/services/patient.service';
import { UserService } from 'src/app/services/user-service.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-delete-patient',
  templateUrl: './delete-patient.component.html',
  styleUrls: ['./delete-patient.component.css']
})
export class DeletePatientComponent implements OnInit {

  formUser = new User();
  user: any;
  error = false;

  operationDone: boolean = false;

  @Input() patientId: string;

  toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 50000,
    timerProgressBar: false,
  });

  constructor(private userService: UserService, private patientService: PatientService, 
    private route: Router) { }

  ngOnInit() {
    this.user = this.userService.getUserInfo();
    if (this.user) {
      this.formUser.username = this.user.username;
    }
  }

  doLoginAndProceedDelete() {
    this.userService.getUserByUsernameAndPassword(this.formUser).then((response) => {
      if (response.status === OsmConstant.STATUS_OK && response.data) {
        this.error = false;
        if (this.patientId) {
          this.patientService.deletePatient(this.patientId).then((response) => {
            console.log(response);
            this.operationDone = true;
            this.route.navigate(["/patient/list"]);
          }).catch((error) => {
            this.toast.fire({
              icon: 'error',
              title: error.statusText ? error.statusText : error.error.message
            })
          })
        }
      } else {
        this.error = true;
      }
    });
  }

}
